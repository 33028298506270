(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("@fiverr/i18n/singleton"), require("axios"), require("classnames"), require("lodash"), require("prop-types"), require("react"), require("react-dom"));
	else if(typeof define === 'function' && define.amd)
		define(["@fiverr/i18n/singleton", "axios", "classnames", "lodash", "prop-types", "react", "react-dom"], factory);
	else {
		var a = typeof exports === 'object' ? factory(require("@fiverr/i18n/singleton"), require("axios"), require("classnames"), require("lodash"), require("prop-types"), require("react"), require("react-dom")) : factory(root["i18n"], root["axios"], root["classNames"], root["_"], root["PropTypes"], root["React17"], root["ReactDOM17"]);
		for(var i in a) (typeof exports === 'object' ? exports : root)[i] = a[i];
	}
})(self, (__WEBPACK_EXTERNAL_MODULE_jgnj__, __WEBPACK_EXTERNAL_MODULE__jHP__, __WEBPACK_EXTERNAL_MODULE_IX_1__, __WEBPACK_EXTERNAL_MODULE_luq0__, __WEBPACK_EXTERNAL_MODULE_JjgO__, __WEBPACK_EXTERNAL_MODULE_yHGu__, __WEBPACK_EXTERNAL_MODULE__4LQx__) => {
return 