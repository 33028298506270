import { Loader } from '@fiverr-private/layout-component';
import { BODY_SIDEBAR_SHOWN_CLASS } from '@fiverr-private/sidebar';

export const loader: Loader = async (hydrateComponent) => {
    if (typeof window === 'undefined' || !window.MutationObserver) {
        await hydrateComponent();
        return;
    }

    const observer = new MutationObserver((mutationsList) => {
        mutationsList.forEach(async (mutation) => {
            const isClassNameChanged = mutation.type === 'attributes' && mutation.attributeName === 'class';

            if (!isClassNameChanged) {
                return;
            }

            const currentClasses = (mutation.target as HTMLBodyElement).classList;

            if (currentClasses.contains(BODY_SIDEBAR_SHOWN_CLASS)) {
                await hydrateComponent();
                observer.disconnect();
            }
        });
    });

    observer.observe(document.body, { attributes: true });
};
