import { LogEntity, logger } from '@fiverr-private/obs';
import { addError } from '@fiverr-private/monicore';
import { getContext } from '@fiverr-private/fiverr_context';

/**
 * Handles CSR errors.
 * @param error The error to handle.
 * @param componentName The component's name.
 * @param entryName The component's entry name.
 */
export const handleError = (error: unknown, componentName: string, entryName: string) => {
    const description = `Layout component: ${componentName}, failed on entry: ${entryName}.`;
    const errorName = `layout.CSR.${componentName}.${entryName}`;
    const loadErrorName = `layout.CSR_LOAD_CHUNK.${componentName}.${entryName}`;
    const context = getContext();
    // See https://github.com/fiverr/layout_sphinx/issues/472.
    if (error instanceof Error && error?.name === 'ChunkLoadError') {
        addError(loadErrorName);
    } else {
        logger.error(error as LogEntity, {
            context,
            description,
            componentName,
            entryName,
            package: '@fiverr-private/layout-sdk',
        });
        addError(errorName);
    }
};
