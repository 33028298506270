import { Component, ErrorInfo, ReactNode } from 'react';
import { handleError } from '../handleError';

interface ErrorBoundaryProps {
    componentName: string;
    entryName: string;
    children: ReactNode;
}

interface ErrorBoundaryState {
    hasError: boolean;
}

export class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
    static getDerivedStateFromError() {
        return { hasError: true };
    }

    constructor(props: ErrorBoundaryProps) {
        super(props);
        this.state = { hasError: false };
    }

    public override componentDidCatch(error: Error, _: ErrorInfo) {
        const { componentName, entryName } = this.props as ErrorBoundaryProps;
        handleError(error, componentName, entryName);
    }

    public override render() {
        if (this.state.hasError) {
            return null;
        }

        return this.props.children;
    }
}
